var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("headbox", {
        attrs: { title: "消息中心", bgColor: "#fff", isborder: "true" }
      }),
      _c(
        "van-pull-refresh",
        {
          staticStyle: { "min-height": "100vh" },
          on: { refresh: _vm.onRefresh },
          model: {
            value: _vm.refreshing,
            callback: function($$v) {
              _vm.refreshing = $$v
            },
            expression: "refreshing"
          }
        },
        [
          _vm.ishow
            ? [
                _c("van-empty", {
                  staticClass: "custom-image",
                  attrs: {
                    image: require("@/assets/images/pic_no information@2x.png"),
                    description: "暂无数据!"
                  }
                })
              ]
            : _vm._e(),
          !_vm.ishow
            ? _c(
                "van-list",
                {
                  attrs: {
                    finished: _vm.finished,
                    "finished-text": "没有更多了"
                  },
                  on: { load: _vm.onLoad },
                  model: {
                    value: _vm.loading,
                    callback: function($$v) {
                      _vm.loading = $$v
                    },
                    expression: "loading"
                  }
                },
                _vm._l(_vm.trapmsg, function(items) {
                  return _c(
                    "div",
                    {
                      key: items.id,
                      staticClass: "transaction_msg",
                      on: {
                        click: function($event) {
                          return _vm.msgdetials(items.id)
                        }
                      }
                    },
                    [
                      _c("div", { staticClass: "flex msg_list rel" }, [
                        _c("div", { staticClass: "iconbox" }, [
                          _c("img", {
                            attrs: {
                              src: require("@/assets/images/message_3.png"),
                              alt: ""
                            }
                          })
                        ]),
                        _c("div", {}, [
                          _c("b", { staticClass: "time" }, [
                            _vm._v(
                              _vm._s(_vm._f("formatDate_")(items.enableTime))
                            )
                          ]),
                          _c("p", { staticClass: "titlebox" }, [
                            _vm._v(_vm._s(items.title))
                          ]),
                          _c("p", { staticClass: "details" }, [
                            _vm._v(
                              _vm._s(_vm._f("delfont")(items.summary)) + "..."
                            )
                          ])
                        ])
                      ])
                    ]
                  )
                }),
                0
              )
            : _vm._e()
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }