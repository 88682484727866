<template>
  <div>
    <headbox title="消息中心" bgColor="#fff" isborder="true"></headbox>
<!--    <van-tabs>
      <van-tab title="系统消息">
        &lt;!&ndash; 系统消息 &ndash;&gt;
        <div
          class="system_msg"
          v-for="(items, index) in adminmsg"
          :key="items.id"
          @click="msgdetials(items.id)"
        >
          <div class="flex msg_list rel">
            <div class="iconbox">
              <img src="@/assets/images/message_2.png" alt="" />
            </div>
            <div class="">
              <b class="time">{{ items.enableTime | formatDate_ }}</b>
              <p class="titlebox">{{ items.title }}</p>
              <p class="details">{{ items.summary }}...</p>
            </div>
          </div>
        </div>
      </van-tab>
      <van-tab title="行情消息">
        &lt;!&ndash; 行情消息 &ndash;&gt;
        <div
          class="quotes_msg"
          v-for="(items, index) in hangqingmsg"
          :key="items.id"
          @click="msgdetials(items.id)"
        >
          <div class="flex msg_list rel">
            <div class="iconbox">
              <img src="@/assets/images/message_1.png" alt="" />
            </div>
            <div class="">
              <b class="time">{{ items.enableTime | formatDate_ }}</b>
              <p class="titlebox">{{ items.title }}</p>
              <p class="details">{{ items.summary }}...</p>
            </div>
          </div>
        </div>
      </van-tab>
      <van-tab title="交易消息">
        &lt;!&ndash; 交易信息 &ndash;&gt;
        <div
          class="transaction_msg"
          v-for="(items, index) in trapmsg"
          :key="items.id"
          @click="msgdetials(items.id)"
        >
          <div class="flex msg_list rel">
            <div class="iconbox">
              <img src="@/assets/images/message_3.png" alt="" />
            </div>
            <div class="">
              <b class="time">{{ items.enableTime | formatDate_ }}</b>
              <p class="titlebox">{{ items.title }}</p>
              <p class="details">{{ items.summary | delfont }}...</p>
            </div>
          </div>
        </div>
      </van-tab>
    </van-tabs>-->
    <van-pull-refresh v-model="refreshing" style="min-height: 100vh;"  @refresh="onRefresh">
      <template v-if="ishow">
        <van-empty
          class="custom-image"
          :image="require('@/assets/images/pic_no information@2x.png')"
          description="暂无数据!"
        />
      </template>
      <van-list
        v-model="loading"
        :finished="finished"
        finished-text="没有更多了"
        @load="onLoad"
        v-if="!ishow"
      >
        <div
          class="transaction_msg"
          v-for="(items) in trapmsg"
          :key="items.id"
          @click="msgdetials(items.id)"
        >
          <div class="flex msg_list rel">
            <div class="iconbox">
              <img src="@/assets/images/message_3.png" alt="" />
            </div>
            <div class="">
              <b class="time">{{ items.enableTime | formatDate_ }}</b>
              <p class="titlebox">{{ items.title }}</p>
              <p class="details">{{ items.summary | delfont }}...</p>
            </div>
          </div>
        </div>
      </van-list>
    </van-pull-refresh>
  </div>
</template>
<script>
import headbox from "@/components/head.vue";
import * as msglist from "@/utils/api";
import { formatDate_ } from "@/utils/filters";
import * as api from "@/utils/api";
import { userTrade } from "@/utils/api";
export default {
  data() {
    return {
      // 交易信息
      trapmsg: [],
      // 行情信息
      hangqingmsg: [],
      // 系统消息
      adminmsg: [],
      page: 0,
      size: 10,
      loading: false,
      finished: false,
      refreshing: false,
      ishow: false,
    };
  },
  filters: {
    formatDate_,
    delfont: function(val) {
      return val.substr(0, 30) + "...";
    }
  },
  components: {
    headbox
  },
  methods: {
    msgdetials(id) {
      this.$router.push({ name: "messageDeatils", query: { id: id } });
    },
    async onRefresh() {
      this.finished = false;
      //请求最新的数据
      const res = await msglist.userTrade("trade", {
        page: 0,
        size: this.size,
      });
      this.page = res.number + 1;
      this.trapmsg = res.content;
      if (this.trapmsg.length === 0) {
        this.ishow = true;
      } else {
        this.ishow = false;
      }
      if (res.last === true) {
        this.finished = true;
      }
      this.refreshing = false;
      this.loading = false;
    },
    onLoad() {
      setTimeout(async() => {
        try{
          const res = await msglist.userTrade("trade", {
            page: this.page,
            size: this.size,
          });
          // 如果加载完数据了 last为true
          // 把上一段 number赋值给page
          this.page = res.number + 1
          // 把数组里的每一项一一铺开添加
          this.trapmsg.push(...res.content)
          if (this.trapmsg.length === 0) {
            this.ishow = true
          }else{
            this.ishow = false
          }
          // 本次加载完毕，就应该把loading改成false，代表本次加载完了，让加载中状态消失
          // 这样才可以继续下次加载
          this.loading = false;
          if (res.last === true) {
            this.finished = true;
          }
        } catch {
          this.ishow = true
        }
      },1000)
    },
  }
};
</script>
<style lang="scss" scoped="true">
::v-deep .van-tabs__nav--line {
  background: #f7f7f7;
  padding-bottom: 0;
}
::v-deep .van-tabs--line .van-tabs__wrap {
  height: auto;
}
::v-deep .van-tab {
  padding: 25px 0;
  font-size: 32px;
  line-height: normal;
}
::v-deep .van-tab--active .van-tab__text--ellipsis {
  color: #ff2d2e;
}
::v-deep .van-tabs__line {
  bottom: 0;
  background-color: #ff2d2e;
}
.tab_title {
  background: #f7f7f7;
  color: #999999;
  font-size: 32px;
  span {
    padding: 25px 30px;
  }
  .active {
    position: relative;
    color: #ff2d2e;
    &::after {
      content: "";
      width: 100%;
      height: 3px;
      background: #ff2d2e;
      position: absolute;
      bottom: 0;
      left: 0;
    }
  }
}
.iconbox {
  width: 65px;
  margin: 2px 30px 0;
}
.titlebox {
  font-size: 30px;
}
.msg_list {
  padding: 30px 0;
  border-bottom: 1px solid #cdcdcd;
  .time {
    position: absolute;
    right: 0;
    color: #b3b3b3;
    font-size: 24px;
    font-weight: normal;
    top: 35px;
    right: 30px;
  }
}
.details {
  font-size: 24px;
  margin-top: 15px;
  color: #b3b3b3;
}
</style>
